'use strict';

//#region Variables
var TENANT = 'partycity';
var MP3_PATH = 'https://partycity.svcs.cnxcit.com/v5/assets/ding.mp3';
var STYLESHEET = 'https://partycity.svcs.cnxcit.com/v5/styling.css';
var CHAT_SVG = 'https://partycity.svcs.cnxcit.com/v5/assets/chat.svg';
var DOMAIN = 'partycity.com';

var DING;
var chatButton;
var idleTimer = null;
var lastMovement = new Date().getTime();
var stopListening = false;
var browserSupported;
var previousMessage;
var previousAuthor;
var chat;

var CnxQuiq = {};
//#endregion

debug(`bootloader initialized: ${(new Date()).toTimeString()}`);
if (typeof Promise === 'undefined') {
  debug('The current browser does not support promises and is not supported.');
}

var viewPortTag = document.createElement('meta');
viewPortTag.name = 'viewport';
viewPortTag.content = 'width=device-width, initial-scale=1';
document.getElementsByTagName('head')[0].appendChild(viewPortTag);
document.body.style.maxWidth = '100%';

// Add Custom Buttons	
var buttonWrapper = document.createElement('div');
buttonWrapper.id = 'chat-button-wrapper';
buttonWrapper.classList.add('quiq-custom-button');
buttonWrapper.innerHTML = `<style>.quiq-hidden { display:none; }</style><div id="chat-button" class="button-container quiq-hidden" style="width: 50px;"><div id="button-icon-container" class="button-icon-container"><img src="${CHAT_SVG}" alt="Click here to chat now" /></div><div class="button-text-container hidden" id="button-text-container"><div class="div-container"><div class="div-text" id="div-text"><span id="chat-button-title" style="display: block; font-weight: bold">Make your party epic!</span><span id="chat-button-subtitle">Chat with a party planner now.</span></div></div></div><div id="minimize-button-container" class="minimize-button-container hidden"><div id="minimize-button" class="minimize-button hidden"><span class="screen-reader-text">Minimize chat invitation</span></div></div></div>`;
buttonWrapper.addEventListener("click", handler);
document.body.append(buttonWrapper);

// Add CSS styles
const styleSheet = document.createElement('link');
styleSheet.type = 'text/css';
styleSheet.rel = 'stylesheet';
styleSheet.href = STYLESHEET;

// Only show the button once the CSS is loaded
styleSheet.onload = function () {
  debug('Stylesheet loaded');
  let buttonContainer = document.getElementById('chat-button');
  buttonContainer.setAttribute('role', 'button');
  buttonContainer.setAttribute('tabindex', '0');
  buttonContainer.classList.remove('quiq-hidden');
};
debug('Appending stylesheet: ' + styleSheet.href);
document.head.appendChild(styleSheet);

function handler(){ 
  addQuiqScript(true);
}

function windowHandler(){
  addQuiqScript(false);
}

// Load Quiq on page scroll
window.addEventListener('scroll', windowHandler);


function addQuiqScript(clicked) {
  debug(`Started loading Quiq...`);
  document.getElementById('chat-button-wrapper').removeEventListener("click", handler);
  window.removeEventListener('scroll', windowHandler);
  debug(`bootloader loading Quiq: ${(new Date()).toTimeString()}`);
  DING = new Audio(MP3_PATH); // https://freesound.org/people/original_sound/sounds/366102/, by original_sound, no modifications made
  const quiqScript = document.createElement('script');
  quiqScript.src = `https://${TENANT}.quiq-api.com/app/chat-ui/index.js`;
  document.head.appendChild(quiqScript);

  quiqScript.onload = function () {
    debug(`Initializing chat`)
    chat = Quiq({
      "pageConfigurationId": "party-city"
    });

    chat.on('statusChanged', function (e) {
      if (e.data.status !== 'initialized') return;

      chat.isSupportedBrowser().then(function (browserSupported) {
        if (browserSupported) {
          debug('Browser supported');
        } else {
          debug('Your browser is unsupported.');
        }
      });
    });

    // Listen for feedback message arrived
    chat.on('webchatTranscriptChanged', function (e) {
      let transcript = e.data.transcript;

      let mutedMessages = [
        "Thanks for the opportunity to hear from you. If that's all, feel free to end the chat. For now, I'll take you back to the main menu.",
        'Thanks for contacting Party City. Goodbye!',
        'This chat has been closed due to inactivity. ',
      ];
      let lastMessage = transcript[transcript.length - 1];
      let author = lastMessage.authorType;
      let message = lastMessage.text;
      let penultimateMessage = '';
      let penultimateAuthor = '';

      debug('A message has arrived');
      debug(`${author} wrote: '${message}'`);

      if (transcript.length > 1) {
        penultimateMessage = transcript[transcript.length - 2].text || '';
        penultimateAuthor = transcript[transcript.length - 2].authorType;
      }
      if (author !== 'Customer') {
        debug("previousMessage: '" + previousMessage + "'\npreviousAuthor: '" + previousAuthor + "'");
        if (message === previousMessage && author === previousAuthor) {
          debug('Duplicate message detected—sound muted.');
        } else if (penultimateMessage.length > 0 && penultimateAuthor !== 'Customer') {
          debug('Sound not played because a sound was recently played.');
        } else if (mutedMessages.includes(message)) {
          debug('Sound not played because this message was muted.');
        } else if (penultimateMessage === message && penultimateAuthor === author) {
          debug('Sound not played because this message was duplicated.');
        } else {
          // Play sound
          debug('Ding!');
          if (DING.duration > 0 && !DING.paused) {
            DING.cloneNode(true).play();
          } else {
            DING.play();
          }
        }
      }

      // if message content is 'Give feedback', show feedback screen
      else if (author === 'Customer' && message === 'Website Feedback' && !(message === previousMessage && author === previousAuthor)) {
        debug('Customer chose to leave feedback');
        // hideElement(document.getElementsByClassName('quiq-webchat-wrapper')[0]);
        chat.hide();
        showFeedbackModal();
      }
      previousMessage = message;
      previousAuthor = author;
    });
    if (clicked) chat.toggle();
  }
  
}


//#region Event and Change Listener Methods

function showFeedbackModal() {

  if (!document.getElementById("appendedAurycScript")){
    const aurycScript = document.createElement('script'); 
    aurycScript.src = `https://cdn.auryc.com/514-partycitycom/container.js`;
    aurycScript.id = "appendedAurycScript";
    document.head.appendChild(aurycScript);
  }
  
  popFeedback();
}


function popFeedback()
{
   if ( typeof popFeedback.counter == 'undefined' ) {
    popFeedback.counter = 0;
   }
   if (popFeedback.counter++ > 20) {
      // Timeout
       // ...
       return false;
   }

   if (typeof window.launchAurycFeedback !== "function") {
       setTimeout(popFeedback, 1000);
       return false;
   }

   // Condition met
   // ...
    window.launchAurycFeedback(1158, true);
    auryc.track('Website Feedback');
   return true;
}

//#endregion

//});

//#region Debug Methods

function debug(message) {
  if (false) {
    console.log('[CnxQuiq] ' + message);
  }
}

function debug_error(message) {
  if (false) {
    console.error('[CnxQuiq] ' + message);
  }
}

//#endregion